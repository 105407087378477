import { parse } from 'date-fns';
import {
	Config,
	GroupFromServer,
	QuestionFromServer,
	TermsChecked,
} from '../../types/magicform';
import { areQuestionConditionsFulfilled } from './checkConditions';

type ErrorsType =
	| 'required'
	| 'max'
	| 'max_file_size'
	| 'format_date'
	| 'limitedMax'
	| 'limitedMin'
	| 'min'
	| 'format_text_idcard'
	| 'format_text_passport'
	| 'format_text_phone'
	| 'format_mail';

const validateEmail = (mail: string) => {
	if (/^[\d+._a-z-]+@([\da-z-]+\.)+[a-z]{2,}$/.test(mail)) {
		return true;
	}

	return false;
};
const checkIfCheckboxEmpty = (answer: Record<string, boolean>) => {
	return Object.keys(answer).every((k) => !answer[k]);
};

const isAnswerEmpty = (question: QuestionFromServer) => {
	return question.input_type_id === 'file'
		? !question.file && !question.answer
		: question.answer === undefined ||
				question.answer === null ||
				question.answer === '' ||
				(question.input_type_id === 'checkbox' &&
					typeof question.answer === 'object' &&
					checkIfCheckboxEmpty(question.answer));
};

const parseDate = (date: string): string | null => {
	const dateObject = parse(date, 'dd/MM/yyyy', new Date());
	return !Number.isNaN(dateObject.valueOf()) ? dateObject.toISOString() : null;
};

const validate = (
	items: GroupFromServer[],
	termsChecked: TermsChecked,
	areTermsPresent: boolean,
	config: Config
): Record<string, string> => {
	const errors: Record<string, ErrorsType> = {};
	const setError = (questionId: string, errorType: ErrorsType) => {
		errors[questionId] = errorType;
	};

	if ((areTermsPresent && !termsChecked.terms) || !termsChecked.privacy) {
		setError('terms', 'required');
	}

	for (const group of items) {
		for (const question of group.questions) {
			if (question.input_type_id === 'section-title') {
				continue;
			}

			if (areQuestionConditionsFulfilled(question, items)) {
				if (question.required && !question.hidden && isAnswerEmpty(question)) {
					setError(question.id, 'required');
				}

				if (question.input_type_id === 'date' && question.answer) {
					try {
						const date = parseDate(question.answer as string);
						if (date === null) {
							throw new Error('Invalid date');
						}
					} catch {
						setError(question.id, 'format_date');
					}
				}

				if (
					question.input_type_id === 'checkbox' &&
					question.answer &&
					typeof question.answer === 'object' &&
					question.regex
				) {
					const formOptions = new Set(
						question.options.map((option) => option.value)
					);

					const validAnswersEntries = Object.entries(question.answer).filter(
						([option, isChecked]) => formOptions.has(option)
					);

					const [ruleType, ruleValue] = question.regex.split('-');

					if (
						ruleType === 'limitedMax' &&
						validAnswersEntries.filter(([option, isChecked]) => isChecked)
							.length > Number.parseInt(ruleValue, 10)
					) {
						setError(question.id, 'limitedMax');
					}

					if (
						ruleType === 'limitedMin' &&
						validAnswersEntries.filter(([option, isChecked]) => isChecked)
							.length < Number.parseInt(ruleValue, 10)
					) {
						setError(question.id, 'limitedMin');
					}
				}

				if (question.input_type_id === 'number') {
					if (
						question.preset == 'User.nameless_companions_expected' &&
						config.maxCompanions &&
						Number.parseInt(question.answer as string) > config.maxCompanions
					) {
						setError(question.id, 'max');
					}

					if (question.regex) {
						if (
							question.regex.split('-')[0] === 'maximum' &&
							question.answer &&
							typeof question.answer === 'string' &&
							Number.parseInt(question.answer) >
								Number.parseInt(question.regex.split('-')[1])
						) {
							setError(question.id, 'max');
						}
						if (
							question.regex.split('-')[0] === 'minimum' &&
							question.answer &&
							typeof question.answer === 'string' &&
							Number.parseInt(question.answer) <
								Number.parseInt(question.regex.split('-')[1])
						) {
							setError(question.id, 'min');
						}
					}
				}

				if (question.input_type_id === 'file' && question.file) {
					const maxFileSizeAllowed = 15_000_000;
					if (question.file && question.file.size > maxFileSizeAllowed) {
						setError(question.id, 'max_file_size');
					}
				}
				if (
					question.input_type_id === 'text' &&
					question.answer &&
					typeof question.answer === 'string'
				) {
					if (question.answer.length !== 9) {
						if (question.regex === 'identityCard') {
							setError(question.id, 'format_text_idcard');
						}
						if (question.regex === 'passport') {
							setError(question.id, 'format_text_passport');
						}
					}
					if (
						question.regex &&
						question.regex === 'phone' &&
						!/^\+*\({0,1}\d{1,4}\){0,1}[\d\s./-]*$/.test(question.answer)
					) {
						setError(question.id, 'format_text_phone');
					}
					if (question.regex === 'mail' && !validateEmail(question.answer)) {
						setError(question.id, 'format_mail');
					}
				}
			}
		}
	}

	return errors;
};

export { validate };
